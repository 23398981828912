import * as React from "react";
import PageComponent from "src/components/pages/learn/types-of-life-insurance";

const Page = ({ location }) => {
  const copy = (
    <div>
      <h2 className="text-blue font-bold mb-2 mt-4">
        ביטוח חיים תקופתי (ריסק)
      </h2>

      <p className="mb-4">
        כשמו כן הוא, זהו ביטוח חיים לתקופה מוגדרת, או למספר שנים קבוע מראש. רוב
        הפוליסות התקופתיות הללו מציעות מחיר פרמיה קבוע שאינו עולה עם השנים
        בהתחייבות ל- 10/15/20/25 או 30 שנה. בסוף תקופה זו הפוליסה מסתיימת או
        שהפרמיה קופצת למחיר כה גבוה שלמעשה כל בעל פוליסה אינו מחדש אותה. תדמיינו
        פוליסה זו בדומה לביטוח רכב. היא מכסה אתכם במקרה של תאונה בלתי צפויה
        (מוות לא צפוי). אם לא הייתה תאונה (לא התרחש מוות בתקופת הפוליסה) לא
        ישולם הכיסוי הביטוחי. ביטוח תקופתי כמעט תמיד ייתן כיסוי למקרה מוות בעלות
        הנמוכה ביותר.
      </p>
      <p className="mb-4">ביטוח זה מתאים יותר לצורכי ביטוח חיים זמניים כמו:</p>
      <ol className="list-decimal mr-4">
        <li>הגנה משפחתי כל עוד הילדים צעירים ותלויים בהורים</li>
        <li>בתקופת נשיאת חוב משכנתא או חוב אחר</li>
        <li>לצורך מימון של הסכם מכירה/ קנייה עסקי בין שותפים</li>
        <li>עלות החלפת עובד מפתח</li>
      </ol>

      <h2 className="text-blue font-bold mb-2 mt-8">ביטוח חיים קבוע</h2>
      <p className="mb-4">
        פוליסת ביטוח חיים קבוע מתוכננת להחזיק תקופת חיים שלמה. ניתן להשיג מטרה
        זו דרך מגוון סוגי חוזים שונים של ביטוח חיים קבוע. ביטוח חיים קבוע מתאים
        במיוחד עבור:
      </p>
      <ol className="list-decimal mr-4">
        <li>צורכי נזילות בנדל”ן כמו מס עזבון</li>
        <li>צורך קבוע בהסדר מכירה/ קנייה עסקי</li>
        <li>שוויון בחלוקת ירושה</li>
        <li>מורשת צדקה</li>
        <li>חיסול חוב לתווך ארוך</li>
      </ol>

      <h3 className="font-bold mt-4 text-xl">
        הנה כמה דוגמאות פופולריות של ביטוח חיים קבוע:
      </h3>

      <h4 className="mt-2 text-blue font-bold text-lg">ביטוח לכל החיים</h4>
      <p className="mb-4">
        פוליסות לכל החיים באות בדרך כלל עם פרמיה קבועה בהתחייבות לכל החיים שחובה
        לשלם אותה לאורך כל חיי הפוליסה. חלק מהפרמיה המשולמת הולכת לחשבון צבירת
        ערך מזומן בתוך הפוליסה, הכסף שהצטבר בחשבון זה שייך לבעל הפוליסה וניתן
        להלוות אותו (בריבית) בזמן שהפוליסה נשארת בתוקף. אם הפוליסה מבוטלת הכסף
        שהצטבר בחשבון המזומן ישולם בחזרה לבעל הפוליסה.
      </p>
      <p className="mb-4">
        רבות מהפוליסות לכל החיים מתוכננות “לתרום”, כלומר, שהשווי הכספי שלהן בגיל
        100 יהיה שווה לכיסוי הפוליסה במקרה מוות. פוליסה לכל החיים מסוג זה היא
        “קלאסית” ומתוכננת כקרן שקיעה להשלמה עצמית, כך שהקרן תשלים 100% מהכיסוי
        בעת מוות בגיל 100 ותשלם את אותם 100% בעת שיתרחש המוות.
      </p>
      <p className="mb-4">
        רבות מהפוליסות לכל החיים “משתתפות”, מה שאומר שהחברה מתכננת להצהיר על
        רווחיה ולשלם דיבידנד לבעל הפוליסה בהתאם לתוצאות הכלכליות של החברה.
        דיבידנדים הם למעשה החזר של פרמיה עודפת, אמנם אין בכך התחייבות
        שהדיבידנדים ישולמו. את הדיבידנדים, כשישולמו, ניתן: למשוך מהחשבון כמשיכת
        מזומן, להשתמש בהם להוריד את מחיר הפרמיה, או לקנות בהם עוד כיסוי ביטוחי
        בתוך הפרמיה.
      </p>

      <h4 className="mt-2 text-blue font-bold text-lg">ביטוח חיים אוניברסלי</h4>

      <p className="mb-4">
        בדומה לביטוח חיים לכל החיים, ביטוח חיים אוניברסלי מתוכנן להחזיק חיים
        שלמים, וחלק מהפרמיה הולכת לחשבון צבירת ערך מזומן. בעוד שהמבנה של ביטוח
        חיים לכל החיים הוא נוקשה, ביטוח חיים אוניברסלי מקדם גמישות. ביטוח חיים
        אוניברסלי מאפשר לבעל הפוליסה לממן את הפוליסה באופן שהכי מתאים לצרכים שלו
        או של המוטב. בעוד שחוזה ביטוח חיים לכל החיים מבקש שהפרמיה תשולם בכל שנה,
        ביטוח חיים אוניברסלי מאפשר לבעל הפוליסה לממן את הפוליסה עם פרמיה מתוכננת
        מראש שניתן לשנות אותה או להפסיק אותה על סמך החזרי ערך המזומן שהצטברו
        בחשבון הפוליסה, וכן לפי צפי של תוחלת החיים. כל עוד העלות של הביטוח
        מקוזזת מערכי הפוליסה בכל חודש, מלא הכיסוי בעת פטירה נותר בתוקף.
      </p>
      <p className="mb-4">
        ביטוח חיים אוניברסלי הוא דרך מאוד אפקטיבית מצד העלות לרכוש ביטוח חיים
        קבוע, אבל אינו מטיל עוד אחריות פוטנציאלית על בעל הפוליסה להבין ולנהל את
        הפוליסה בהשוואה לביטוח חיים לכל החיים.
      </p>
      <p className="mb-4">
        לאחרונה, החברות המבטחות יצרו ביטוח חיים אוניברסלי ללא עלייה במחיר הפרמיה
        בהתחייבות לאותו המחיר, המשמעות היא שכל עוד הפרמיה ללא עליה במחיר משולמת,
        הפוליסה נשארת בתוקף מלא גם לאחר גיל 100. בעל הפוליסה עדיין נהנה מגמישות
        בתשלום הפרמיה שהביטוח חיים האוניברסלי מכיל. המחיר הקבוע שאינו עולה
        בהתחייבות נוטה לצבור מעט מאוד ערך מזומן ולעיתים אף כלל לא, אבל הוא נותן
        פרמיה יציבה לכל החיים בהתחייבות יחד עם גמישות להפחית ואף לחסל תשלומי
        פרמיה בתגובה לנסיבות החיים כמו ידיעה ברורה שתוחלת החיים של המבוטח לא
        תגיע לגיל 100 שנה ולאחר מכן. פרמיה במחיר קבוע מסוג זה היא דרך יעילה מאוד
        מבחינת העלות שלה להבטיח תגמול כספי לאורך כל החיים במקרה של מוות ללא צורך
        לשלם יותר ממה שצריך לחברת הביטוח.
      </p>

      <h4 className="mt-2 text-blue font-bold text-lg">
        וריאציות ביטוח חיים לכל החיים או ביטוח אוניברסלי
      </h4>

      <h5 className="mt-2 font-semibold">וריאציה לכל החיים</h5>
      <p className="mb-4">
        חוזה מגוון שמאפשר לערך המזומן שהצטבר בפוליסה להיות מושקע בקרן נאמנות
        בכדי להשיג פוטנציאל החזרים גבוהה יותר מאשר ההחזר המוצהר של חברת הביטוח.
        סוג זה של חוזה נחשב כהון עצמי להשקעה ונופל תחת הרגולציות של השקעה.
      </p>

      <h5 className="mt-2 font-semibold">“אינדקס” לכל החיים</h5>
      <p className="mb-4">
        קונספט דומה לוריאציה הקודמת אבל את הכסף שהצטבר בפוליסה ניתן להשקיע רק
        בקרנות “אינדקס” (כמו אס אנד פי 500), יש בדרך כלל מכסה, או גובה החזר
        מקסימלי שייזקף לזכות בעל הפוליסה, אבל מצד שני גם תחתית החזר מינימלית
        שתיזקף לבעל הפוליסה. הפוליסה לא בהכרח תתפוס את גובה השוק אבל גם לא תביא
        להחזר שלילי.
      </p>
      <p className="mb-4">
        שתי הוריאציות הנ”ל משמשות ככלי השקעה פטור ממס בתוספת הגנה של כיסוי בעת
        פטירה.
      </p>
    </div>
  );
  return (
    <PageComponent
      lang="he"
      location={location}
      copy={copy}
      pageTitle="סוגים של ביטוחי חיים"
    />
  );
};

export default Page;
